.code-inline, pre {
    font-size: 15px !important;
    line-height: 1.73em;
    padding: 4px 8px;
    margin-left: 4px;
    margin-right: 4px;
    border: none !important;
    background: black !important;
    font-family: "JetBrains Mono",monospace;
    font-weight: 600;
    color: white !important;
}

body, html {
    background-color: #131f24 !important;
}